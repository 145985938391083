<template>
  <b-container fluid>
    <form @submit.prevent="saveData">
      <b-row>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <span class="card-title h4"><b>NUEVA PREGUNTA</b></span>
            </template>
            <div class="row">
              <div class="col-xl-3">
                <h4 class="px-4 py-4"><b>Información General</b></h4>
              </div>
            </div>
            <div class="row">
              <div class="col-12 pt-3 pl-5 mb-4">
                <span class="  "><b> Competencia:</b></span>
                <span class="card-title h6 mt rounded-sm py-2 px-3 ml-3" style="background-color: #0c1a30 !important"
                  ><b>1.4 - IV - (NOM035-GR1-TODOS) Afectación: Durante el último mes.</b></span
                >
              </div>
            </div>
            <div class="row px-5">
              <div class="col-12 mb-3">
                <label for=" " class="form-label">Pregunta</label>
                <input required v-model="pregunta" placeholder="Escriba la pregunta..." type="text" class="form-control" id=" " />
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Tipo de Pregunta</label>
                  <b-form-select required @change="tipoSel($event)" value-field="id" text-field="descripcion" :options="tipoPregunta"></b-form-select>
                </div>
              </div>
            </div>
            <!-- pregunta de control -->
            <div class="row px-5">
              <div class="col-12 mb-3">
                <label for=" " class="form-label">Pregunta de control</label>
                <input  v-model="preguntaControl" placeholder="Escriba la pregunta..." type="text" class="form-control" id=" " />
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Activar pregunta de control</label>
                  <div class="form-check form-switch">
                    <label class="form-check-label" :for="'aflexSwitchCheckDefault'"> </label>
                    <VueToggles @click="preguntaControlAc()" height="20" width="50" :value="PreguntaControlActiva"  />
                  </div>
                </div>
              </div>
            </div>
          </card>
        </b-col>
      </b-row>
      <!-- si / no -->
      <b-row>
        <b-col sm="12">
          <card>
            <div class="row">
              <div class="col-10">
                <h4 class="px-4 py-4"><b>Definición de preguntas</b></h4>
              </div>
              <div class="col-2 pt-3 text-right pr-5">
                <button class="mx-1 btn btn-success text-right mt-1" @click.prevent="agregarFila()"><i class="fas fa-plus ml-1"></i></button>
              </div>
            </div>

            <div class="row px-5 mb-3" v-for="(it, i) of listaItems" :key="it.id">
              <div class="col-11">
                <label for=" " class="form-label">Fila # {{ i + 1 }}</label>
                <input required v-model="it.pregunta" placeholder="Escriba la pregunta..." type="text" class="form-control" id=" " />
              </div>
              <div class="col-1">
                <button @click.prevent="removerFila(it)" class="btn btn-secondary mt-4">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
          </card>
        </b-col>
      </b-row>
      <!-- multiple -->
      <b-row>
        <b-col sm="12">
          <card>
            <div class="row">
              <div class="col-10">
                <h4 class="px-4 py-4"><b>Definición de calificaciones</b></h4>
              </div>
              <div class="col-2 pt-3 text-right pr-5">
                <button class="mx-1 btn btn-success text-right mt-1" @click.prevent="agregarValoracion()"><i class="fas fa-plus ml-1"></i></button>
                <!-- <span class="  "><b> Agregar Fila:</b></span> -->
                <!-- <span class="card-title h6 mt rounded-sm py-2 px-3 ml-3" style="background-color: #0c1a30 !important"><button class="mx-1 btn btn-success text-right mt-4" @click="$router.push('/helex/Preguntas_agregar/'+id)"><i class="fas fa-plus"></i>Agregar pregunta</button></span> -->
              </div>
            </div>

            <div class="row px-5 mb-3" v-for="(itv, ii) of listaCalificaciones" :key="itv.id">
              <div class="col-8">
                <div>
                  <label for=" " class="form-label">opción #{{ ii + 1 }}</label>
                  <input required :disabled="tipoPreguntaSel.descripcion == 'Si/No'" v-model="itv.opcion" placeholder="Escriba la pregunta..." type="text" class="form-control" id=" " />
                </div>
              </div>
              <div class="col-3">
                <div>
                  <label for=" " class="form-label">Valor</label>
                  <input required :disabled="tipoPreguntaSel.descripcion == 'Si/No'" v-model="itv.valor" placeholder="Escriba la pregunta..." type="number" class="form-control" id=" " />
                </div>
              </div>
              <div class="col-1">
                <button @click.prevent="removerValoracion(itv)" class="btn btn-secondary mt-4">
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
          </card>
        </b-col>
      </b-row>
      <div class="text-right px-5 pb-4">
        <button type="submit" class="mx-1 btn px-4 py-2 btn-primary">Guardar Pregunta</button>
        <!-- <button class="mx-1 btn px-4 py-2 btn-secondary" @click="$router.push('/helex/Preguntas')">Cancelar Registro</button>                  -->
      </div>
    </form>
  </b-container>
</template>
<script>
import { core } from "../../../config/pluginInit";
import Swal from "sweetalert2";

export default {
  name: "DataTable",
  data() {
    return {
      options: [
        { id: 1, text: "Si / No", value: "si_no" },
        { id: 2, text: "Preguntas multiples", value: "multiple" },
      ],
      tabla_test: [
        {
          id: 1,
          numero_doc: "252527",
          nombre: "LOG2 Demo",
          contraseñas_rep: "721189",
          cargo: "Operario de Conducción",
          estado: "Activo",
        },
        {
          id: 3,
          numero_doc: "252527",
          nombre: "LOG2 Demo",
          contraseñas_rep: "721189",
          cargo: "Operario de Conducción",
          estado: "Activo",
        },
        {
          id: 4,
          numero_doc: "252527",
          nombre: "LOG2 Demo",
          contraseñas_rep: "721189",
          cargo: "Operario de Conducción",
          estado: "Activo",
        },
        {
          id: 5,
          numero_doc: "252527",
          nombre: "LOG2 Demo",
          contraseñas_rep: "721189",
          cargo: "Operario de Conducción",
          estado: "Activo",
        },
        {
          id: 6,
          numero_doc: "252527",
          nombre: "LOG2 Demo",
          contraseñas_rep: "721189",
          cargo: "Operario de Conducción",
          estado: "Activo",
        },
      ],
      id: "",
      pregunta: "",
      tipoPregunta: [],
      tipoPreguntaSel: {},
      listaItems: [{ id: 1, pregunta: "" }],
      listaCalificaciones: [{ id: 1, opcion: "", valor: null }],
      preguntaControl:null,
      PreguntaControlActiva:false

    };
  },
  mounted() {
    this.id = this.$route.params.id;
    core.index();
    window.$("#datatable_empresa").DataTable();
    this.getTipoPreguntas();
  },
  methods: {
    preguntaControlAc(){
      this.PreguntaControlActiva = !this.PreguntaControlActiva
    },
        showToast(type, message) {
      console.log(type);
      if (type == "success") {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (type == "error")
        this.$toast.error(message, {
          position: "top-center",
          timeout: 1992,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      if (type == "warning")
        this.$toast.warning(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
    },
    async saveData() {
      //validaciones
      if(this.PreguntaControlActiva==true && (this.preguntaControl== null || this.preguntaControl=="")){
        this.showToast("error", 'Falta pregunta de control');
        return
      }
      if(this.listaItems.length==0){
        this.showToast("error", 'Faltan items');
        return
      }
      if(this.listaCalificaciones.length==0){
        this.showToast("error", 'Faltan calificaciones');
        return
      }

      //guardar pregunta en CompetenciaPregunta
      try {
        this.$isLoading(true);

        let CompetenciaPregunta = {
          Pregunta: this.pregunta,
          CompetenciaId: this.id,
          TipoPreguntaId: this.tipoPreguntaSel.id,
          preguntaControl: this.preguntaControl,
          PreguntaControlActiva: this.PreguntaControlActiva
        };
        console.log(CompetenciaPregunta);
        let resPregunta = await this.$store.dispatch("hl_post", {
          path: "CompetenciaPregunta/Create/",
          data: CompetenciaPregunta,
        });
        console.log(resPregunta);
        //guardar datos en PreguntaDetalle
        let dataPreguntaDetalle = [];
        for await (let itemDetalle of this.listaItems) {
          let PreguntaDetalle = {
            Descripcion: itemDetalle.pregunta,
            PreguntaId: resPregunta,
          };
          dataPreguntaDetalle.push(PreguntaDetalle);
        }
        console.log(dataPreguntaDetalle);
        let resDetalle = await this.$store.dispatch("hl_post", {
          path: "PreguntaDetalle/CreatePreguntaDetalleList/",
          data: dataPreguntaDetalle,
        });
        console.log(resDetalle);
  
        //guardar datos en PreguntaDetalleMovimiento
        let dataPreguntaDetalleMov = [];
        for await (let itemDetalleMov of this.listaCalificaciones) {
          let PreguntaDetalleMov = {
            Descripcion: itemDetalleMov.opcion,
            Valoracion: itemDetalleMov.valor,
            PreguntaId: resPregunta,
          };
  
          dataPreguntaDetalleMov.push(PreguntaDetalleMov);
        }
        console.log(dataPreguntaDetalleMov);
        await this.$store.dispatch("hl_post", {
          path: "PreguntaDetalleMovimiento/CreatePreguntaDetalleMovimientoList/",
          data: dataPreguntaDetalleMov,
        });
        console.log(resDetalle);
        this.$isLoading(false);
        //limpiar variables y mostrar pregunta en lista
        this.$router.push('/helex/Preguntas_lista/'+this.id)
        Swal.fire("Pregunta almacenada correctamente!", "", "info");

      } catch (error) {
        this.$isLoading(false);
        console.log(error)
 
      }

    },
    removerValoracion(e) {
      if (this.tipoPreguntaSel.descripcion != "Si/No") {
        let filter = this.listaCalificaciones.indexOf(e);
        this.listaCalificaciones.splice(filter, 1);
      }
    },
    agregarValoracion() {
      if (this.tipoPreguntaSel.descripcion != "Si/No") {
        let maxId = Math.max(...this.listaCalificaciones.map((o) => o.id));
        let data = {
          id: maxId + 1,
          opcion: "",
          valor: null,
        };
        this.listaCalificaciones.push(data);
      }
    },
    removerFila(e) {
      let filter = this.listaItems.indexOf(e);
      this.listaItems.splice(filter, 1);
    },
    agregarFila() {
      let maxId = Math.max(...this.listaItems.map((o) => o.id));
      let data = {
        id: maxId + 1,
        pregunta: "",
      };
      this.listaItems.push(data);
    },
    tipoSel(e) {
      console.log(e);
      this.tipoPreguntaSel = this.tipoPregunta.find((x) => x.id == e);
      console.log(this.tipoPreguntaSel);
      this.listaCalificaciones = [];
      if (this.tipoPreguntaSel.descripcion == "Si/No") {
        let data0 = {
          id: 0,
          opcion: "Si",
          valor: 100,
        };
        let data1 = {
          id: 1,
          opcion: "No",
          valor: 0,
        };
        this.listaCalificaciones.push(data0);
        this.listaCalificaciones.push(data1);
      } else {
        let data = {
          id: 0,
          opcion: "",
          valor: null,
        };
        this.listaCalificaciones.push(data);
      }
    },
    async getTipoPreguntas() {
      // console.log(this.persona.empresa.id);
      try {
        console.log("obteniendo preguntas...");
        let res = await this.$store.dispatch("hl_post", {
          path: "TipoPregunta/List/",
        });
        console.log("tipoPregunta ", res);
        if (res.length > 0) {
          this.tipoPregunta = res;
        } else {
          this.tipoPregunta = [];
        }
      } catch (error) {
        this.tipoPregunta = [];
        console.log("err", error);
      }
    },
  },
};
</script>
<style>
.bg-dark {
  background-color: #122a50 !important;
}
</style>
